import { render, staticRenderFns } from "./add.vue?vue&type=template&id=496f29e2&scoped=true"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496f29e2",
  null
  
)

export default component.exports